import {
    getMassResultsColumns, mapDataFunction,
    operationMarkup as oms,
    operations as ops, useColumns
} from "../Stages/Shared";
import {Col, Row} from "antd";
import StageManager from "../Stages/StageManager";
import {PermissionsContext} from "../../lib/PermissionsContext";
import {useState, useEffect, useContext} from "react";

export function Unpackaged() {

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [total, setTotal] = useState();
    const [queryParams, setQueryParams] = useState({
        status : 1
    }); 

    const {columns, filters} = useColumns();
    const restColumns = columns.concat(getMassResultsColumns());

    const {hasPermission} = useContext(PermissionsContext)
    const canEdit = hasPermission('assigment.edit');

    const getQueryParams = () => {
        const params = {};
        params['status'] = 1;
        params['page'] = page;
        params['pageSize'] = pageSize;
        return params;
    }

    useEffect(() => {
        setQueryParams(getQueryParams());
    }, [page, pageSize]);

    const mapFunction = (rawData) => {
        const {data, meta} = rawData;
        const mapped = mapDataFunction(data);
        setTotal(meta.total);
        return mapped;
    }

    function getOperations() {
        return [
            [
                oms[ops.JOIN], oms[ops.DEFECT],
            ],
            [
                oms[ops.SPLIT], oms[ops.NEXT_STAGE],
            ]
        ];
    }

    return <Row>
        <Col span={24}>
            <StageManager
                operationButtons={getOperations()}
                columns={restColumns}
                mapFucntion={mapFunction}
                url={"/api/stock"}
                queryParams={queryParams}
                filter={filters}
                extraFilter={null}
                options={{
                    showStockBase: true,
                }}
                stage={'unpackaged'}
                canEdit={canEdit}
                pagination={{
                    pageSizeOptions: ['50', '100', '250', '500', '1000', '2000', '3000'],
                    pageSize: pageSize,
                    total: total,
                    defaultPageSize: pageSize,
                    onChange: (page, pageSize) => {
                        setPageSize(pageSize);
                    }
                }}
                onDataTableChange={(pagination, filters, sorter, extra) => {
                    setPage(pagination.current);
                }}
            />
        </Col>
    </Row>;
}
