import Crud from "../../lib/Crud";
import {useContext, useState} from "react";
import {OrderedListOutlined} from "@ant-design/icons";
import {Button} from "antd";
import MaterialsOnStock from "./MaterialsOnStock";
import {numSorter, stringSorter} from "../Stages/Shared";
import PropTypes from "prop-types";
import {formatFIO} from "../../lib/util";
import {PermissionsContext} from "../../lib/PermissionsContext";


const Materials = (props) => {

    const [editVariantMode, setEditVariantMode] = useState(false);
    const [materialId, setMaterialId] = useState(null);
    const {hasPermission} = useContext(PermissionsContext);

    const canViewMaterials = hasPermission('material-list.view');
    const canEditMaterials = hasPermission('material-list.edit');
    const canViewMaterialParties = hasPermission('production.material-list.view');
    const canEditMaterialParties = hasPermission('production.material-list.edit');

    return <>
        <Crud title={'Материалы'}
              url={'/api/material'}
              canEdit={canEditMaterials}
              viewConfig={[
                  {
                      title: 'Наименование', dataIndex: 'name', key: 'name', width: '200px',
                      sorter: stringSorter('name'),
                  },
                  {
                      title: 'Тип', dataIndex: 'is_composite', key: 'is_composite', width: '200px',
                      sorter: numSorter('is_composite'),
                      render: (text, record) => {
                          return record.is_composite ? 'Составной' : 'Простой'
                      }
                  },
                  {
                      title: 'Автор', dataIndex: 'created_name', key: 'created_name', width: '200px',
                      render: (text, record) => {
                          return formatFIO(record.created?.name || '-');
                      }
                  }
              ]}
              editConfig={() => {
                  let result = [
                      {title: 'Наименование', type: 'text', name: 'name',},
                      {title: 'Составной', type: 'checkbox', name: 'is_composite',},
                      {
                          title: 'Состав', type: 'list', name: 'components',
                          selector: <Materials selectionMode={'multiple'} showComposite={false}/>,
                      },
                  ];
                  return result;
              }}
              editTitle={'Редактирование материала'}
              createTitle={'Создание материала'}
              onMapLoad={(data, setPageSize, setTotalRecords) => {
                  let result = [];
                  if (props.showComposite === false) {
                      result = data.data.filter(item => item.is_composite === false);
                  } else {
                      result = data.data;
                  }
                  if (data.meta?.per_page && data.meta?.total) {
                      setPageSize(data.meta.per_page);
                      setTotalRecords(data.meta.total);
                  }
                  return result;
              }}
              onDataFilter={(data) => {
                  if (!canViewMaterials) {
                      return data.filter(item => item.is_composite === true);
                  }
                  return data;
              }}
              onMapSave={data => {
                  if (data.components && data.components.length > 0) {
                      if (data.components[0].id) {
                          data.components = data.components.map(item => item.id);
                      }
                  }
                  return data;
              }}
              customAction={(record) => {
                  if (canViewMaterialParties) {
                      return <Button size={"small"} shape={'circle'}
                                     className={'copy-btn'}
                                     onClick={e => {
                                         setMaterialId(record.id);
                                         setEditVariantMode(true);
                                     }}>
                          <OrderedListOutlined/>
                      </Button>
                  }
              }}
              onRenderField={(field, record) => {
                  // давать редактировать тип материала только при создании
                  if (field.name === 'is_composite') {
                      return record.id === null || record.id === undefined;
                  }
                  if (field.name === 'components') {
                      return record.is_composite === true;
                  } else {
                      return true;
                  }
              }}
              createRecordEnabled={() => {
                  return canEditMaterials;
              }}
              editorWidth={'800px'}
              {...props}
        />
        {editVariantMode ?
            <MaterialsOnStock canEdit={canEditMaterialParties} materialId={materialId}
                              onClose={() => setEditVariantMode(false)}/> : null}

    </>;

}

Materials.propTypes = {
    showComposite: PropTypes.bool,
    selectionMode: PropTypes.oneOf(['single', 'multiple']),
};

Materials.defaultProps = {
    showComposite: true,
}

export default Materials;
