import {Col, Row, Select, Space, Table, Modal, ConfigProvider, Input} from "antd";
import {useEffect, useState} from "react";
import locale from 'antd/lib/locale/ru_RU';
import {getDefaultOperationColumns} from "../Shared";
import {OperationsApi, ServerOperations} from "../Operatoins";
import MaterialParties from "../../Material/MaterialParties";
import FileIds from "../../../components/FileList/FileIds";
import NoFutureDate from "../../../components/NoFutureDate";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";

export function Phoresis(props) {

    const [id, setId] = useState(null);
    const [concentration, setConcentration] = useState();
    const [started, setStarted] = useState();
    const [finished, setFinished] = useState();
    const [comment, setComment] = useState();
    const [queueId, setQueueId] = useState();
    const [materials, setMaterials] = useState([]);
    const [files, setFiles] = useState([]);
    const [cellCount, setCellCount] = useState(0);

    const [portionsToSend, setPortionsToSend] = useState();

    function onOk() {
        const materialIds = (materials && materials.length && materials.map(item => item.id)) || [];
        if (queueId) {
            OperationsApi.editOperation(ServerOperations.PHORESIS, queueId, portionsToSend, {materials: materialIds}).then((result) => {
                props.onHide();
            });
        } else {
            OperationsApi.doOperation(ServerOperations.PHORESIS, portionsToSend, {materials: materialIds}).then((result) => {
                props.onHide();
            });
        }
    }

    const concentrationOptions = [
        {label: '8%', value: 8},
        {label: '10%', value: 10},
        {label: '12%', value: 12},
        {label: '15%', value: 15},
        {label: '18%', value: 18},
        {label: '20%', value: 20, default: true},
    ];

    function handleChangeConcentration(value) {
        setConcentration(value);
    }

    function updateData() {
        const dataToSend = props.data.map(item => ({
            id: item.id, params: {
                concentration: concentration,
                cell_count: cellCount,
                files: files,
                started_at: started,
                finished_at: finished,
                comment,
            }
        }));
        setPortionsToSend(dataToSend);
    }

    function OKDisabled() {
        const IsFilesRequired = finished ? files.length > 0 : true;
        return !(concentration && started && cellCount && IsFilesRequired);
    }

    function getColumns() {
        let columns = getDefaultOperationColumns();
        columns.push({
                title: 'Комментарий', dataIndex: 'stage_comment', key: 'stage_comment', width: 250,
            }
        );
        return columns;
    }

    useEffect(() => {
        updateData();
    }, [concentration, cellCount, started, finished, files, comment]);

    useEffect(() => {
        if (!props.data[0].phoresis) {
            setConcentration(concentrationOptions.find(c => c.default).value);
        } else {
            if (props.data[0].phoresis.pivot?.params?.finished_at) {
                setConcentration(concentrationOptions.find(c => c.default).value);
            }
        }
    }, [])

    useEffect(() => {
        if (props.data[0].phoresis?.pivot?.params) {
            if (!props.data[0].phoresis.pivot.params.finished_at) {
                setId(props.data[0].phoresis.pivot.id);
                setConcentration(props.data[0].phoresis.pivot.params.concentration);
                setCellCount(props.data[0].phoresis.pivot.params.cell_count);
                setStarted(props.data[0].phoresis.pivot.params.started_at);
                setFinished(props.data[0].phoresis.pivot.params.finished_at);
                setComment(props.data[0].phoresis.pivot.params.comment);
                setFiles(props.data[0].phoresis.pivot.params.files);
                setQueueId(props.data[0].phoresis.pivot.portion_to_operation_id);
                setMaterials(props.data[0].phoresis.params.materials);
            }
        }
    }, [props.data]);

    return <Modal
        title="Форез"
        open={true}
        width={1300}
        okButtonProps={{disabled: OKDisabled()}}
        onOk={onOk}
        onCancel={props.onHide}
        okText={'OK'}
        cancelText={'Отмена'}
        destroyOnClose={true}
    >
        <Row>
            <Col span={24}>
                <table style={{width: '100%'}}>
                    <tbody>
                    <tr>
                        <td colSpan={2} style={{padding: 5}}>Дата и время начала<br/>
                            <ConfigProvider locale={locale}>
                                <NoFutureDate format={'DD.MM.YYYY HH:mm'} value={started ? dayjs(started) : null}
                                              placeholder={'Дата и время'}
                                              showTime
                                              onChange={(value) => setStarted(value)}
                                />
                            </ConfigProvider>
                        </td>
                        <td style={{padding: 5}}>
                            Дата и время окончания<br/>
                            <ConfigProvider locale={locale}>
                                <NoFutureDate format={'DD.MM.YYYY HH:mm'} value={finished ? dayjs(finished) : null}
                                              placeholder={'Дата и время'}
                                              showTime
                                              onChange={(value) => setFinished(value)}
                                />
                            </ConfigProvider>
                        </td>
                    </tr>
                    <tr>
                        <td style={{padding: 5}}>
                            Концентрация
                            <Select style={{width: 150}} value={concentration}
                                    options={concentrationOptions}
                                    onChange={handleChangeConcentration}/>
                        </td>
                        <td style={{padding: 5}}>
                            Количество ячеек<br/>
                            <Input type="number" value={cellCount} onChange={(e) => {
                                setCellCount(e.target.value)
                            }} style={{width: 150}}
                            />
                        </td>
                        <td rowSpan='2' colSpan='1' style={{padding: 5}}>
                            Комментарий
                            <TextArea value={comment} onChange={(e) => setComment(e.target.value)}/>
                        </td>
                    </tr>
                    <tr>
                    </tr>
                    </tbody>
                </table>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <FileIds ids={files} onChange={(f) => setFiles(f)}/>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <MaterialParties onChange={(v) => {
                    setMaterials(v);
                }} initialValue={materials}/>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Table rowKey="id" size={'small'} dataSource={props.data} columns={getColumns()}
                       style={{marginTop: 10}}/>
            </Col>
        </Row>

    </Modal>

}
