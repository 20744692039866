import {RetweetOutlined, SubnodeOutlined} from "@ant-design/icons";
import React from "react";

export const Iconizer = ({text}) => {
    const replacementMap = [
        {
            text: 'Переключение этапа производства',
            icon: <RetweetOutlined className="inline-block align-middle" aria-label={"Переключение этапа производства"}
                                   alt="Переключение этапа производства"/>
        },
        {
            text: 'Добавление операции',
            icon: <SubnodeOutlined className="inline-block align-middle" aria-label={"Добавление операции"}
                                   alt="Добавление операции"/>
        }
    ];
    const replacement = replacementMap.find(r => text.includes(r.text));
    if (replacement) {
        const remainingText = text.replace(replacement.text, '').trim();
        return (
            <span className="inline-flex items-center gap-1">
                {replacement.icon}
                {remainingText && <span style={{marginLeft: 5}}>{remainingText}</span>}
      </span>
        );
    }
    return <span>{text}</span>;
};