import {Col, Row, Select, Table, Modal, ConfigProvider, Input, Button, Form} from "antd";
import NoFutureDate from "../../../components/NoFutureDate";
import {useEffect, useRef, useState} from "react";
import dayjs from "dayjs";
import locale from 'antd/lib/locale/ru_RU';
import {OperationsApi, ServerOperations} from "../Operatoins";
import "../../../assets/Deblock.scss";
import {getDefaultOperationColumns} from "../Shared";
import MaterialParties from "../../Material/MaterialParties";
import TextArea from "antd/lib/input/TextArea";
import api from "../../../lib/util";
import PrevMaterials from "../../Synth/components/PrevMaterials";
import {NotifyError} from "../../../lib/notify";

export function Click(props) {

    const [id, setId] = useState(null);

    const [started, setStarted] = useState();
    const [finished, setFinished] = useState();
    const [comment, setComment] = useState();

    const [data, setData] = useState(props.data);
    const [portionsToSend, setPortionsToSend] = useState([]);
    const [queueId, setQueueId] = useState();

    const [paint, setPaint] = useState();
    const [paintDir, setPaintDir] = useState([]);

    const [materials, setMaterials] = useState();

    const [showPrevMaterials, setShowPrevMaterials] = useState(false);

    const [loading, setLoading] = useState(false);

    const recalc = useRef(false);
    const [calcError, setCalcError] = useState(false);

    function onOk() {

        const materialIds = (materials && materials.length && materials.map(item => item.id)) || [];

        if (queueId) {
            OperationsApi.editOperation(ServerOperations.CLICK, queueId, portionsToSend, {materials: materialIds}, false).then((result) => {
                props.onHide();
            }).catch((e) => {
                NotifyError("Повторная регистрация операции недоступна");
            }).finally(() => {
                setLoading(false);
            });
        } else {
            OperationsApi.doOperation(ServerOperations.CLICK, portionsToSend, {materials: materialIds}, false).then((result) => {
                props.onHide();
            }).catch((e) => {
                NotifyError("Повторная регистрация операции недоступна");
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    function getColumns() {
        const columns = getDefaultOperationColumns();
        return columns.concat([
            {
                id: 'V', dataIndex: 'V', title: 'Краска, мкл', width: 120, render: (value, item) => {
                    return <Input value={value} onChange={(e) => {
                        item.V = e.target.value;
                        updateData();
                    }} onBlur={() => {
                        setDefaultValue('V', item, value);
                    }} type={"number"}/>
                }
            },
            {
                id: 'Y', dataIndex: 'Y', title: 'ТЕАА, мкл', width: 120, render: (value, item) => {
                    return <Input value={value} onChange={(e) => {
                        item.Y = e.target.value;
                        updateData();
                    }} onBlur={() => {
                        setDefaultValue('Y', item, value);
                    }} type={"number"}/>
                }
            },
            {
                id: 'Q', dataIndex: 'Q', title: 'СТОК, мкл', width: 120, render: (value, item) => {
                    return <Input value={value} onChange={(e) => {
                        item.Q = e.target.value;
                        updateData();
                    }} onBlur={() => {
                        setDefaultValue('Q', item, value);
                    }} type={"number"}/>
                }
            },
            {
                id: 'W', dataIndex: 'W', title: 'ДМСО, мкл', width: 120, render: (value, item) => {
                    return <Input value={value} onChange={(e) => {
                        item.W = e.target.value;
                        updateData();
                    }} onBlur={() => {
                        setDefaultValue('W', item, value);
                    }} type={"number"}/>
                }
            },
            {
                id: 'K', dataIndex: 'K', title: 'Аскорбиновая кислота, мкл', width: 120, render: (value, item) => {
                    return <Input value={value} onChange={(e) => {
                        item.K = e.target.value;
                        updateData();
                    }} onBlur={() => {
                        setDefaultValue('K', item, value);
                    }} type={"number"}/>
                }
            },
            {
                id: 'F', dataIndex: 'F', title: 'Вода, мкл', width: 120, render: (value, item) => {
                    return <Input value={value} onChange={(e) => {
                        item.F = e.target.value;
                        updateData();
                    }} onBlur={() => {
                        setDefaultValue('F', item, value);
                    }} type={"number"}/>
                }
            },
            {
                id: 'M', dataIndex: 'M', title: 'Общий объем, мкл', width: 120, render: (value, item) => {
                    return <Input value={value} onChange={(e) => {
                        item.M = e.target.value;
                        updateData();
                    }} onBlur={() => {
                        setDefaultValue('M', item, value);
                    }} type={"number"}/>
                }
            },
            {
                id: 'N', dataIndex: 'N', title: 'Конечная доля ДМСО, %', width: 120, render: (value, item) => {
                    return <Input value={value} onChange={(e) => {
                        item.N = e.target.value;
                        updateData();
                    }} onBlur={() => {
                        setDefaultValue('N', item, value);
                    }} type={"number"}/>
                }
            },
            {
                id: 'S', dataIndex: 'S', title: 'ЭДТА, мкл', width: 120, render: (value, item) => {
                    return <Input value={value} onChange={(e) => {
                        item.S = e.target.value;
                        updateData();
                    }} onBlur={() => {
                        setDefaultValue('S', item, value);
                    }} type={"number"}/>
                }
            },
        ]);
    }

    function handleChangePaint(value) {
        setPaint(value);
        recalc.current = true;
    }

    function updateData() {
        const dataToSend = data.map(item => ({
                id: item.id, params: {
                    started_at: started, finished_at: finished,
                    paint, comment,
                    V: item.V, W: item.W, Y: item.Y, Q: item.Q, K: item.K, F: item.F, N: item.N, M: item.M, S: item.S,
                }
            }
        ));
        setPortionsToSend(dataToSend);
    }

    function OKDisabled() {
        return !paint || !started || calcError;
    }

    const loadPaintDir = () => {
        setLoading(true);
        return new Promise((resolve, reject) => {
            api.getJSON('/api/conjunction/directory').then((result) => {
                setPaintDir(() => result);
                setLoading(false);
                resolve();
            });
        });
    }

    const getPaintOptions = () => {
        return paintDir.map(item => {
            return {
                label: item.name,
                value: item.id
            }
        });
    }


    useEffect(() => {
        updateData();
    }, [started, finished, comment])

    useEffect(() => {
        loadPaintDir().then(() => {

            const params = props.data[0].click?.pivot?.params || props.data[0].params;
            const materials = props.data[0].click?.params?.materials || props.data[0].params?.materials;
            const portionToOperationId = props.data[0].click?.pivot?.portion_to_operation_id;

            if (params) {
                if (!params.finished_at) {
                    setId(params.id);
                    setQueueId(portionToOperationId);
                    setPaint(params.params.paint);
                    setStarted(params.params.started_at);
                    setMaterials(materials);
                    setComment(params.params.comment);
                    const newData = props.data.map(item => {
                        return {
                            ...item,
                            comment: params.comment,
                            V: params.V,
                            W: params.W,
                            Y: params.Y,
                            Q: params.Q,
                            K: params.K,
                            F: params.F,
                            N: params.N,
                            M: params.M,
                            S: params.S,
                        }
                    });
                    setData(newData);
                    setPortionsToSend(newData);
                }
            }
        });
    }, [props.data]);

    const getDefaultValue = async (column, item) => {
        setLoading(true);
        const result = await api.postJSON(`/api/conjunction/calculate/${item.id}/${paint}`, {});
        setLoading(false);
        return result[column];
    }

    const setDefaultValue = (column, item, value) => {
        if (value === '' && paint) {
            getDefaultValue(column, item).then((result) => {
                item[column] = result;
                updateData();
            });
        } else {
            item[column] = value;
            updateData();
        }
    }

    const calcAll = () => {
        setCalcError(false);
        return new Promise((resolve, reject) => {
            const records = [...data];
            if (paint) {
                const promises = data.map(item =>
                    api.postJSON(`/api/conjunction/calculate/${item.id}/${paint}`, {})
                        .then((result) => {
                            records[records.findIndex(i => i.id === item.id)] = {...item, ...result};
                        })
                );
                Promise.all(promises).then(() => {
                    setData([...records]);
                    resolve();
                }).catch((e => {
                    if (e.errors) {
                        const messages = Object.values(e.errors).map((item) => item[0]);
                        messages.forEach((message) => NotifyError(message));
                    }
                    setCalcError(true);
                    reject(e);
                }));
            }
        });
    }

    useEffect(() => {
        if ((paint && !props.data[0].click?.pivot?.params) || recalc.current) {
            setLoading(true);
            calcAll().then(() => {
                updateData();
                setLoading(false);
                recalc.current = false;
            }).finally(() => {
                setLoading(false);
            }).catch(() => {
                setLoading(false);
            });
        }
    }, [paint]);


    const url = paint ? `/api/operation/last-operation/${ServerOperations.CLICK}?params[paint]=${paint}` : `/api/operation/last-operation/${ServerOperations.CLICK}`;

    return <><Modal
        title="Клик"
        open={true}
        width={'99%'}
        okButtonProps={{disabled: OKDisabled()}}
        onOk={onOk}
        onCancel={props.onHide}
        okText={'OK'}
        cancelText={'Отмена'}
        destroyOnClose={true}
    >
        <Row style={{marginBottom: 20}}>
            <Col span={24}>
                <Form layout={'inline'}>
                    <Form.Item label="Фактическое время и дата начала">
                        <ConfigProvider locale={locale}>
                            <NoFutureDate format={'DD.MM.YYYY HH:mm'} value={started ? dayjs(started) : null}
                                          placeholder={'Дата и время'}
                                          showTime
                                          onChange={(value) => setStarted(value)}/>
                        </ConfigProvider>
                    </Form.Item>
                    <Form.Item label="Фактическое время и дата завершения">
                        <ConfigProvider locale={locale}>
                            <NoFutureDate format={'DD.MM.YYYY HH:mm'} value={finished ? dayjs(finished) : null}
                                          placeholder={'Дата и время'} showTime
                                          onChange={(value) => setFinished(value)}/>
                        </ConfigProvider>
                    </Form.Item>
                    <Form.Item label="Краска">
                        <Select style={{display: 'inline-flex', width: 200}} value={paint} options={getPaintOptions()}
                                onChange={handleChangePaint}/></Form.Item>
                    <Form.Item label="Комментарий">
                        <TextArea value={comment} onChange={(e) => setComment(e.target.value)}
                                  style={{width: '480px'}}/>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <MaterialParties onChange={(v) => {
                    setMaterials(v);
                }} initialValue={materials}
                                 slot={<><Button style={{marginLeft: 10}} type={'primary'} disabled={!paint}
                                                 onClick={() => setShowPrevMaterials(true)}>Выбрать материалы из
                                     последней операции</Button></>}
                />
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                {data !== undefined && data.length > 0 &&
                    <Table size={'small'} rowKey="id" dataSource={data} columns={getColumns()}
                           scroll={{x: 2500}} loading={loading}/>}
            </Col>
        </Row>
    </Modal>
        {showPrevMaterials && <PrevMaterials url={url} onOk={(materials) => {
            setMaterials(materials);
            setShowPrevMaterials(false);
        }} onCancel={() => {
            setShowPrevMaterials(false);
        }} onHide={() => {
            setShowPrevMaterials(false);
        }} title={"Выбор материалов из последней операции"}/>
        }
    </>
}
