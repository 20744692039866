import {useEffect, useState} from "react";
import {Col, Row} from "antd";
import List from "../../lib/List";
import ChooseMaterialParty from "./ChooseMaterialParty";
import PropTypes from "prop-types";
import {formatFIO} from "../../lib/util";


const MaterialParties = (props) => {

    const [parties, setParties] = useState(props.initialValue);

    const onChange = (value) => {
        props.onChange(value);
    }

    const selectedIds = () => {
        return parties?.map(party => party.id) || [];
    }

    useEffect(() => {
        setParties(props.initialValue);
    }, [props.initialValue]);

    return (
        <div style={{marginTop: 0, marginBottom: 10}}>
            {props.showTitle && <Row>
                <Col span={24}>
                    <h3>{props.title}</h3>
                </Col>
            </Row>}
            <Row>
                <Col span={24}>
                    <List columns={[
                        // {title: 'id', dataIndex: 'id', key: 'id'},
                        {title: 'Наименование', dataIndex: 'name', key: 'name'},
                        {title: 'Производитель', dataIndex: 'manufacturer', key: 'manufacturer'},
                        {title: 'Номер партии', dataIndex: 'batch_number', key: 'batch_number'},
                        {title: 'Каталожный номер', dataIndex: 'catalogue_number', key: 'catalogue_number'},
                        {
                            title: 'Дата производства',
                            dataIndex: 'production_date',
                            key: 'production_date',
                            render: (text) => {
                                return text ? new Date(text).toLocaleDateString('ru-RU') : <span>&mdash;</span>;
                            }
                        },
                        {
                            title: 'Годен до',
                            dataIndex: 'sell_by',
                            key: 'sell_by',
                            render: (text) => {
                                return text ? new Date(text).toLocaleDateString('ru-RU') : <span>&mdash;</span>;
                                ;
                            }
                        },
                        {
                            title: 'Автор', dataIndex: 'name', key: 'name', render: (text, record) => {
                                return formatFIO(record?.created?.name);
                            }
                        },
                    ]}
                          returnId={false}
                          selector={<ChooseMaterialParty type={props.type} selectedIds={selectedIds()}/>}
                          onChange={onChange}
                          value={parties}
                          slot={props.slot}
                    />
                </Col>
            </Row>
        </div>
    );
}

MaterialParties.propTypes = {
    initialValue: PropTypes.array,
    onChange: PropTypes.func,
    showTitle: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.string,
    slot: PropTypes.element,
}

MaterialParties.defaultProps = {
    initialValue: [],
    showTitle: true,
    title: 'Расходные материалы',
    type: 'checkbox',
}


export default MaterialParties;
