import React, {useState} from "react";
import TableCustomizer, {loadColumnSettings} from "./TableCustomizer";
import {copyToClipboard} from "../lib/util";
import {Table} from "antd";
import PropTypes from "prop-types";

const Popup = ({record, visible, x, y, onMenuClick}) => {
    const selectedText = window.getSelection().toString();
    return visible && <ul className="popup" style={{left: `${x}px`, top: `${y}px`}}>
        <li key={1} onClick={() => {
            onMenuClick("copy", selectedText);
        }}>Копировать
        </li>
        <li className={"delimiter"}></li>
        <li onClick={() => onMenuClick("edit")}>Настроить столбцы</li>
    </ul>;
}

const applyColumnSettings = (tablename, columns) => {
    const colSettings = loadColumnSettings(tablename);
    if (Object.keys(colSettings.widths).length > 0) {
        columns = columns.map(column => {
            if (colSettings.widths && colSettings.widths[column.dataIndex]) {
                column.width = colSettings.widths[column.dataIndex];
            }
            return column;
        });
    }

    if (Object.keys(colSettings.fixed).length > 0) {
        columns = columns.map(column => {
            if (colSettings.fixed) {
                if (colSettings.fixed[column.dataIndex] === 'left') {
                    column.fixed = 'left';
                } else if (column.fixed === 'left') {
                    column.fixed = null;
                }
            }
            return column;
        });
    }

    if (colSettings.hidden.length > 0) {
        columns = columns.filter(column => {
            return !colSettings.hidden.includes(column.dataIndex);
        });
    }

    // если есть порядок столбцов, то сортируем их
    if (colSettings.order.length > 0) {
        columns = colSettings.order
            .map(dataIndex => columns.find(item => item.dataIndex === dataIndex))
            .filter(Boolean);
    }

    // передвинуть все колонки с fixed в начало/конец
    const leftColumns = columns.filter(column => (column.fixed === 'left'));
    const rightColumns = columns.filter(column => (column.fixed === 'right'));
    columns = columns.filter(column => !column.fixed);
    columns = [...leftColumns, ...columns, ...rightColumns];

    return columns;
}


const CTable = React.forwardRef((props, ref) => {

    const {tableName, columns, ...rest} = props;
    const [showColumnsEditor, setShowColumnsEditor] = useState(false);
    const [popup, setPopup] = useState({record: null, visible: false, x: 0, y: 0});

    const onRow = record => ({
        onContextMenu: event => {
            event.preventDefault();
            if (!popup.visible) {
                document.addEventListener("click", function onClickOutside() {
                    setPopup({record: null, visible: false, x: 0, y: 0});
                    document.removeEventListener("click", onClickOutside)
                });
            }
            let x = event.clientX;
            let y = event.clientY;

            const el = document.querySelector('.data-table');

            if (el) {
                const rect = el.getBoundingClientRect();
                x = event.clientX - rect.left;
                y = event.clientY - rect.top;
            }

            setPopup({
                record,
                visible: true,
                x, y
            });
        }
    });


    return <>
        <Table {...rest} ref={ref} onRow={onRow} columns={applyColumnSettings(tableName, columns)}
               className={'data-table'}
        />
        <Popup {...popup} onMenuClick={(action, selectedText = "") => {
            if (action === 'edit') {
                setShowColumnsEditor(true);
            }
            if (action === 'copy') {
                if (selectedText) {
                    copyToClipboard(selectedText);
                }
            }
        }}/>
        {showColumnsEditor && <TableCustomizer stage={tableName} initialColumns={props.columns} open={true}
                                               onHide={() => setShowColumnsEditor(false)}/>}

    </>
});

CTable.propTypes = {
    tableName: PropTypes.string
}

CTable.defaultProps = {
    tableName: 'default'
}


export default CTable;