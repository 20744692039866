import {Col, Row, Form, Modal, ConfigProvider, Input, Select, Space, Table, Button} from "antd";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import locale from 'antd/lib/locale/ru_RU';
import {OperationsApi, ServerOperations} from "../Operatoins";
import "../../../assets/Deblock.scss";
import {getDefaultOperationColumns} from "../Shared";
import MaterialParties from "../../Material/MaterialParties";
import NoFutureDate from "../../../components/NoFutureDate";
import TextArea from "antd/lib/input/TextArea";
import api from "../../../lib/util";
import {NotifyError} from "../../../lib/notify";
import PrevMaterials from "../../Synth/components/PrevMaterials";

export function Acylation(props) {

    const [started, setStarted] = useState();
    const [finished, setFinished] = useState();
    const [comment, setComment] = useState();
    const [portionsToSend, setPortionsToSend] = useState([]);
    const [data, setData] = useState(props.data);

    const [id, setId] = useState(null);
    const [queueId, setQueueId] = useState();
    const [materials, setMaterials] = useState([]);
    const [paint, setPaint] = useState();
    const [paintDir, setPaintDir] = useState([]);

    const [showPrevMaterials, setShowPrevMaterials] = useState(false);

    const loadPaintDir = () => {
        return new Promise((resolve, reject) => {
            api.getJSON('/api/acylation/directory').then((result) => {
                setPaintDir(() => result);
                resolve();
            });
        });
    }

    const getPaintOptions = () => {
        return paintDir.map(item => {
            return {
                label: item.name,
                value: item.id
            }
        });
    }


    function onOk() {
        const materialIds = (materials && materials.length && materials.map(item => item.id)) || [];

        if (queueId) {
            OperationsApi.editOperation(ServerOperations.ACYLATION, queueId, portionsToSend, {materials: materialIds}, false).then((result) => {
                props.onHide();
            }).catch((e) => {
                NotifyError("Повторная регистрация операции недоступна");
            });
        } else {
            OperationsApi.doOperation(ServerOperations.ACYLATION, portionsToSend, {materials: materialIds}, false).then((result) => {
                props.onHide();
            }).catch((e) => {
                NotifyError("Регистрация операции недоступна");
            });
        }
    }

    function updateData() {
        const dataToSend = props.data.map(item => ({
                id: item.id, params: {
                    started_at: started, finished_at: finished,
                    paint,
                    a: item['a'], b: item['b'], c: item['c'], d: item['d'],
                    comment
                }
            }
        ));
        setPortionsToSend(dataToSend);
    }

    function OKDisabled() {
        return !started || !paint;
    }

    const setDefaults = () => {
        const data = [...props.data];
        props.data.forEach(item => {
            item['a'] = item.quantity;
            item['b'] = item.quantity;
            item['c'] = item.quantity;
            item['d'] = (item.quantity * 2) / 50;
            const idx = data.findIndex(i => i.id === item.id);
            data[idx] = item;
        });
        setData(data);
    }

    useEffect(() => {
        updateData();
    }, [started, finished, comment, paint]);

    useEffect(() => {
        loadPaintDir().then(() => {
            if (props.data[0].acylation?.pivot?.params) {
                if (!props.data[0].acylation.pivot.params.finished_at) {
                    setId(props.data[0].acylation.pivot.id);
                    setQueueId(props.data[0].acylation.pivot.portion_to_operation_id);
                    setStarted(props.data[0].acylation.pivot.params.started_at);
                    setFinished(props.data[0].acylation.pivot.params.finished_at);
                    setComment(props.data[0].acylation.pivot.params.comment);
                    setPaint(props.data[0].acylation.pivot.params.paint);
                    setMaterials(props.data[0].acylation.params.materials);

                    const data = [...props.data];
                    props.data.forEach(item => {
                        item['a'] = item.acylation.pivot.params.a;
                        item['b'] = item.acylation.pivot.params.b;
                        item['c'] = item.acylation.pivot.params.c;
                        item['d'] = item.acylation.pivot.params.d;
                        const idx = data.findIndex(i => i.id === item.id);
                        data[idx] = item;
                    });
                    setData(data);
                } else {
                    setDefaults();
                }
            } else {
                setDefaults();
            }
        });
    }, [])

    function calc(row) {
        const _X = row['quantity'] || 0;
        const _A = parseFloat(row['a'] || _X);
        const _B = parseFloat(row['b'] || _X);
        const _C = parseFloat(row['c'] || _X);
        const _D = row['d'] || ((_X * 2) / 50);
        row['a'] = _A;
        row['b'] = _B;
        row['c'] = _C;
        row['d'] = _D;
        updateData();
    }

    function getColumns() {
        let columns = getDefaultOperationColumns();
        columns = columns.concat([{
            title: 'Краска, мкл', dataIndex: 'a', key: 'a', render: (text, record) => {
                return <Input value={text} onChange={(e) => {
                    record['a'] = e.target.value;
                    updateData();
                }} onBlur={() => calc(record)}/>
            }
        }, {
            title: 'ДМСО, мкл', dataIndex: 'b', key: 'b', render: (text, record) => {
                return <Input value={text} onChange={(e) => {
                    record['b'] = e.target.value;
                    updateData();
                }} onBlur={() => calc(record)}/>
            }
        }, {
            title: 'Гидрокарбонат натрия, мкл', dataIndex: 'c', key: 'c', render: (text, record) => {
                return <Input value={text} onChange={(e) => {
                    record['c'] = e.target.value;
                    updateData();
                }} onBlur={() => calc(record)}/>
            }
        }, {
            title: 'Уксусная кислота (ледяная), мкл', dataIndex: 'd', key: 'd', render: (text, record) => {
                return <Input value={text} onChange={(e) => {
                    record['d'] = e.target.value;
                    calc(record);
                }}/>
            }
        }]);

        return columns;
    }

    function handleChangePaint(value) {
        setPaint(value);
    }

    const url = paint ? `/api/operation/last-operation/${ServerOperations.ACYLATION}?params[paint]=${paint}` : `/api/operation/last-operation/${ServerOperations.ACYLATION}`;

    return <><Modal
        title="Ацилирование"
        open={true}
        width={1400}
        okButtonProps={{disabled: OKDisabled()}}
        onOk={onOk}
        onCancel={props.onHide}
        okText={'OK'}
        cancelText={'Отмена'}
        destroyOnClose={true}
    >
        <Row style={{marginBottom: 20}}>
            <Col span={12}>
                <Space direction={'vertical'}>
                    <Form.Item label={'Фактическое время и дата начала'}>
                        <ConfigProvider locale={locale}>
                            <NoFutureDate format={'DD.MM.YYYY HH:mm'} value={started ? dayjs(started) : null}
                                          placeholder={'Дата и время'}
                                          showTime
                                          onChange={(value) => setStarted(value)}/>
                        </ConfigProvider>
                    </Form.Item>
                    <label className={'label'}>Комментарий</label>
                    <TextArea value={comment} onChange={(e) => setComment(e.target.value)}
                              style={{width: '460px'}}/>
                </Space>
            </Col>
            <Col span={12}>
                <Space direction={'vertical'}>
                    <Form.Item label={'Фактическое время и дата завершения'}>
                        <ConfigProvider locale={locale}>
                            <NoFutureDate format={'DD.MM.YYYY HH:mm'} value={finished ? dayjs(finished) : null}
                                          placeholder={'Дата и время'} showTime
                                          onChange={(value) => setFinished(value)}/>
                        </ConfigProvider>
                    </Form.Item>
                    <Form.Item label={'Краска'}>
                        <Select style={{display: 'inline-flex', width: 200}} value={paint} options={getPaintOptions()}
                                onChange={handleChangePaint}/>
                    </Form.Item>
                </Space>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <MaterialParties onChange={(v) => {
                    setMaterials(v);
                }} initialValue={materials}
                                 slot={<><Button style={{marginLeft: 10}} type={'primary'} disabled={!paint}
                                                 onClick={() => setShowPrevMaterials(true)}>Выбрать материалы из
                                     последней операции</Button></>}
                />
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <Table rowKey="id" size={'small'} dataSource={props.data} columns={getColumns()}
                       scroll={{x: 1000}}/>
            </Col>
        </Row>
    </Modal>
        {showPrevMaterials && <PrevMaterials url={url} onOk={(materials) => {
            setMaterials(materials);
            setShowPrevMaterials(false);
        }} onCancel={() => {
            setShowPrevMaterials(false);
        }} onHide={() => {
            setShowPrevMaterials(false);
        }} title={'Выбор материалов из последней операции'}/>
        }
    </>;

}
