import React, {useEffect, useRef, useState} from "react";
import PageHeader from "../../components/mock/PageHeader";
import api, {downloadFileWithPOST} from "../../lib/util";
import {Button, Col, ConfigProvider, Form, Input, Modal, Row, Select, Table} from "antd";
import {isObject} from "underscore";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {ParamNames} from "./Shared";
import {Iconizer} from "../../components/Iconizer";
import {NotifyError, NotifySuccess} from "../../lib/notify";
import MaterialParties from "../Material/MaterialParties";
import PropTypes from "prop-types";
import NoFutureDate from "../../components/NoFutureDate";
import locale from 'antd/lib/locale/ru_RU';
import dayjs from "dayjs";
import {operationNames, OperationsApi, ServerOperations} from "./Operatoins";

const renderMaterials = (materials) => {
    if (Array.isArray(materials)) {
        return <table>
            <thead>
            <tr>
                <th>Название</th>
                <th>Кат. №</th>
                <th>Партия</th>
                <th>Производитель</th>
            </tr>
            </thead>
            <tbody>
            {materials.map((item, index) => {
                return <tr key={index}>
                    <td>{item.material_name}</td>
                    <td>{item.catalogue_number}</td>
                    <td>{item.batch_number}
                    </td>
                    <td>{item.manufacturer}</td>
                </tr>;
            })}
            </tbody>
        </table>
    } else {
        return '';
    }
}

const isEditableOperation = (operationId) => {
    return [
        ServerOperations.DEBLOCK,
        ServerOperations.REMOVE_FROM_CARRIER,
        ServerOperations.OE_MEASURING,
        ServerOperations.EVAPORATION,
        ServerOperations.SEATING,
        ServerOperations.GLENPACK,
        ServerOperations.DISSOLUTION_FILTRATION,
        ServerOperations.DESALINATION,
        ServerOperations.PHORESIS,
        ServerOperations.MKL_MEASURING,
        ServerOperations.MASS_RESULTS,
        ServerOperations.DETRITYLATION,
        ServerOperations.REMOVE_MALONATE
    ].includes(operationId);
}

const PropsEditor = ({props, onChange}) => {

    const [params, setParams] = useState(props);
    const ref = useRef(null);
    delete props.materials;

    useEffect(() => {
        onChange(params);
    }, [params]);

    return <Form ref={ref}>
        {Object.keys(params).map((key, index) => {
            const label = ParamNames[key] || key;
            const fieldType = key === 'started_at' || key === 'finished_at' ? 'date' : 'text';
            return <React.Fragment key={index}>
                {fieldType === 'text' && <Form.Item key={index} label={label} labelAlign={'right'} labelCol={{span: 6}}>
                    <Input value={params[key]} onChange={(e) => {
                        setParams({...params, [key]: e.target.value});
                    }}/>
                </Form.Item>}
                {fieldType === 'date' && <ConfigProvider locale={locale}>
                    <Form.Item key={index} label={label} labelAlign={'right'} labelCol={{span: 6}}>
                        <NoFutureDate format={'DD.MM.YYYY HH:mm'} value={params[key] ? dayjs(params[key]) : null}
                                      placeholder={'Дата и время'}
                                      showTime
                                      onChange={(value) => setParams({...params, [key]: value})}/>
                    </Form.Item>
                </ConfigProvider>
                }
            </React.Fragment>;
        })}
    </Form>;
}

PropsEditor.propTypes = {
    props: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

const PropsWindow = (props) => {

    const [materials, setMaterials] = useState(props.materials || []);
    const [params, setParams] = useState(props.params);

    const showMaterials = !([
        ServerOperations.OE_MEASURING,
        ServerOperations.EVAPORATION,
        ServerOperations.MKL_MEASURING,
        ServerOperations.MASS_RESULTS,
    ].includes(props.operationId));

    const onCancel = () => {
        props.onCancel();
    }
    const onOk = () => {
        props.onOk({materials, params});
    }

    return <Modal open={true} onCancel={onCancel} onOk={onOk} width={900}
                  title={`Редактирование операции «${props.opName}»`}>
        <div style={{marginTop: 20}}>
            <PropsEditor props={params} onChange={(data) => {
                setParams(data);
            }}/>
            {showMaterials && <MaterialParties onChange={(v) => {
                setMaterials(v);
            }} initialValue={materials}/>}
        </div>
    </Modal>

}

PropsWindow.propTypes = {
    params: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    materials: PropTypes.array,
    opName: PropTypes.string,
    operationId: PropTypes.number.isRequired,
}

const PortionHistory = (props) => {

    const [loading, setLoading] = React.useState(false);
    const [portion, setPortion] = React.useState([]);
    const [filterStage, setFilterStage] = React.useState([]);
    const [events, setEvents] = React.useState([]);
    const [filterOperation, setFilterOperation] = React.useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);
    const [showEditor, setShowEditor] = React.useState(false);

    const [params, setParams] = React.useState({});
    const [materials, setMaterials] = React.useState([]);
    const [operation, setOperation] = React.useState({});

    const load = () => {
        setLoading(true);
        api.getJSON(`/api/portion/history-by-events/${props.match.params.id}`).then((data) => {
            const portionData = {
                id: data.id,
                portion_id: data.id,
                olig_name: data.oligonucleotid.full_sequence,
                prefix: data.prefix,
                oligonucleotid: data.oligonucleotid,
                device_place_name: data.device_place_name,
                device_place_position: data.device_place_position,
                types: data.oligonucleotid?.types?.map(item => item.name).join(', '),
                nanomole: data.nanomole,
                quantity: data.oligonucleotid.quantity,
                parents: data.parents,
                children: data.children,
            };
            setPortion(portionData);
            const e = parseEvent(data.events);
            setEvents(e);
        }).finally(() => {
            setLoading(false);
        });
    }

    const parseEvent = (data) => {
        return data.map((item, index) => {
            return {
                index: ++index,
                id: item.id,
                name: item.name,
                created_at: item.created_at,
                created_by: item.author.name,
                events: item.events && item.events.length > 0 ? parseEvent(item.events) : [],
                params: item.params,
                payload: item.payload,
                materials: item.materials,
                portionToOperationId: item.portionToOperationId,
            }
        });
    }

    const renderEvents = (events) => {
        if (!events) {
            return '';
        }
        return events.map((item) => {
            return <div key={item.id}>
                <div>{item.text}</div>
                <div>{item.created_at}</div>
                <div>{item.author.name}</div>
                <div>{item.data}</div>
                <div>{renderEvents(item.events)}</div>
            </div>;
        });
    }

    const editOperation = (record) => {
        setOperation(record);
        setParams(record.payload);
        setMaterials(record.materials);
        setShowEditor(true);
    }

    const deleteOperation = (operation) => {
        if (window.confirm('Операция будет удалена безвозвратно. Продолжить?')) {
            const queueId = operation.portionToOperationId;
            if (!queueId) {
                NotifyError('Не найден идентификатор операции');
                return;
            }
            api.postJSON('/api/portion/delete-operation', {portion_to_operation_ids: [queueId]}).then((resp) => {
                if (resp.errors) {
                    NotifyError('Ошибка при удалении операции');
                    return;
                }
                NotifySuccess('Операция удалена');
                load();
            }).catch(() => {
                NotifyError('Ошибка при удалении операции');
            })
        }
    }

    const saveOperation = (data) => {
        const operationId = operation.id
        const queueId = operation.portionToOperationId;
        const portionsToSend = [{id: portion.id, params: data.params}];
        const materialIds = data.materials?.map(item => item.id) || [];

        return new Promise((resolve, reject) => {
            OperationsApi.editOperation(operationId, queueId, portionsToSend, {materials: materialIds}, {
                ...data.params,
                materials: materialIds
            }).then((result) => {
                NotifySuccess('Операция сохранена');
                resolve(result);
            }).catch((e) => {
                NotifyError(e);
                reject(e);
            });
        });
    }

    const getColumns = () => [
        {dataIndex: 'index', title: '№', width: 40},
        {
            dataIndex: 'name', title: 'Название', width: 200, render: (value) => {
                return <Iconizer text={value}/>
            }
        },
        {
            dataIndex: 'created_at', title: 'Дата', width: 200, render: (value) => {
                return value ? new Date(value).toLocaleString() : '';
            }
        },
        {
            dataIndex: 'created_by',
            title: 'Сотрудник',
            width: 200,
            render: (value, record) => {
                return value;
            }
        },
        {
            dataIndex: 'params',
            title: 'Данные',
            width: 150,
            render: (params) => {
                if (!params || !isObject(params)) {
                    return;
                }
                return Object.keys(params).map((key, index) => {
                    if (!params[key]) {
                        return '';
                    }
                    if (key === 'Файлы' && params[key]) {
                        return params[key].map(item => {
                            return <div key={index}><strong>{key}</strong>: <Button onClick={function () {
                                downloadFileWithPOST('/api/file/' + item);
                            }}>Скачать</Button></div>;
                        });
                    }
                    return <div key={index}><strong>{key}</strong>: {params[key]}</div>
                });
            }
        },
        {
            dataIndex: 'materials',
            title: 'Материалы',
            width: 300,
            render: renderMaterials,
        },
        {
            dataIndex: 'actions',
            title: 'Действия',
            width: 80,
            render: (_, record) => {
                const disabled = !record.portionToOperationId;
                if (record.id && isEditableOperation(record.id)) {
                    return <div>
                        <Button disabled={disabled} icon={<EditOutlined/>} size={'small'} className={'del-btn'}
                                onClick={() => editOperation(record)} shape={'circle'}/>
                        <Button disabled={disabled} icon={<DeleteOutlined/>} size={'small'} className={'edit-btn'}
                                onClick={() => deleteOperation(record)} shape={'circle'}/>
                    </div>;
                }
            }
        }
    ];

    const renderPortionHeader = () => {
        return <table style={{width: '100%'}}>
            <thead>
            <tr>
                <th align={'left'}>ID</th>
                <th align={'left'}>Название</th>
                <th align={'left'}>Префикс</th>
                <th align={'left'}>Рабочая последовательность</th>
                <th align={'left'}>Целевая последовательность</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td align={'left'}>{portion?.portion_id}</td>
                <td align={'left'}>{portion?.olig_name || '-'}</td>
                <td align={'left'}>{portion?.prefix || '-'}</td>
                <td align={'left'}>{portion?.work_sequence}</td>
                <td align={'left'}>{portion?.target_sequence}</td>
            </tr>
            </tbody>
        </table>;
    }

    const getAllExpandableRowKeys = (data) => {
        let keys = [];
        data.forEach(item => {
            if (item.events && item.events.length > 0) {
                keys.push(item.index);
                keys = keys.concat(getAllExpandableRowKeys(item.events));
            }
        });
        return keys;
    };

    const handleExpandAll = (expand) => {
        if (expand) {
            const allKeys = getAllExpandableRowKeys(events);
            setExpandedRowKeys(allKeys);
        } else {
            setExpandedRowKeys([]);
        }
    };

    const expandableProps = {
        expandedRowKeys: expandedRowKeys,
        onExpandedRowsChange: (keys) => setExpandedRowKeys(keys),
        expandedRowRender: (record, index) => {
            return <Row style={{margin: 5, marginLeft: 48}} key={index}>
                <Col span={24}>
                    <Table
                        rowKey={'index'}
                        columns={getColumns()}
                        dataSource={record.events}
                        pagination={false}
                        expandable={{
                            ...expandableProps,
                            expandedRowKeys: expandedRowKeys
                        }}
                    />
                </Col>
            </Row>;
        },
        rowExpandable: (record) => record.events?.length > 0
    };

    useEffect(() => {
        const id = props.match.params.id;
        if (id) {
            load();
        }
    }, []);

    useEffect(() => {
        if (events.length > 0) {
            const allKeys = getAllExpandableRowKeys(events);
            setExpandedRowKeys(allKeys); // Comment this line if you don't want auto-expand
        }
    }, [events]);

    const operationName = operationNames[operation.id] || '';

    const renderPortionLinks = (portions) => {
        if (!portions || portions.length === 0) {
            return <span>Отсутствуют</span>;
        }

        return (
            <table className="portions-table">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Название</th>
                    <th>Префикс</th>
                    <th>Рабочая последовательность</th>
                    <th>Целевая последовательность</th>
                </tr>
                </thead>
                <tbody>
                {portions.map(item => (
                    <tr key={item.id}>
                        <td>
                            <a
                                href={`/portion/${item.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {item.id}
                            </a>
                        </td>
                        <td>{item.oligonucleotid?.full_sequence || '-'}</td>
                        <td>{item.prefix || '-'}</td>
                        <td>{item.oligonucleotid?.sequence || '-'}</td>
                        <td>{item.oligonucleotid?.target_sequence || '-'}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        );
    };

    return <>
        {showEditor &&
            <PropsWindow params={params} materials={materials} opName={operationName} operationId={operation.id}
                         onCancel={() => setShowEditor(false)}
                         onOk={(data) => {
                             setMaterials(data.materials);
                             setParams(data.params);
                             saveOperation({params: data.params, materials: data.materials}).then(() => {
                                 setShowEditor(false);
                                 load();
                             });
                         }}/>}
        <PageHeader title={"История пробирки"} showFavorite={false}/>
        <Row style={{margin: 24}}>
            <Col span={20}>
                <Form layout={'inline'}>
                    <Form.Item label={"Этап"}>
                        <Select value={filterStage} options={[]} style={{width: 400}}
                                onChange={(value) => {
                                    setFilterStage(value);
                                }}
                                allowClear={true}
                                mode={'multiple'}
                        />
                    </Form.Item>
                    <Form.Item label={"Операция"}>
                        <Select value={filterOperation} options={[]} style={{width: 800}}
                                onChange={(value) => {
                                    setFilterOperation(value);
                                }}
                                allowClear={true}
                                mode={'multiple'}
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col span={4} style={{display: 'flex', justifyContent: 'end'}}>
                <Button onClick={() => {
                    handleExpandAll(!expandedRowKeys.length);
                }}>
                    {expandedRowKeys.length ? 'Свернуть все' : 'Развернуть все'}
                </Button>
            </Col>
        </Row>
        <Row style={{margin: 24}}>
            <Col span={24}>
                {renderPortionHeader()}
            </Col>
        </Row>
        <Row style={{margin: 24}}>
            <Col span={24}>
                <Table
                    rowKey={"index"}
                    columns={getColumns()}
                    dataSource={events}
                    loading={loading}
                    pagination={false}
                    expandable={expandableProps}
                    className={'bold-header'}
                />
            </Col>
        </Row>
        <Row style={{margin: 24}}>
            <Col span={24}>
                <div className="section-header">
                    <h3>Родительские пробирки:</h3>
                </div>
                {renderPortionLinks(portion.parents)}
            </Col>
        </Row>
        <Row style={{margin: 24}}>
            <Col span={24}>
                <div className="section-header">
                    <h3>Дочерние пробирки:</h3>
                </div>
                {renderPortionLinks(portion.children)}
            </Col>
        </Row>
    </>;

}
export default PortionHistory;
